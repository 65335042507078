export default function smoothScrollTo() {
  // Select all the links that have `href` that starts with '#'
  let links = document.querySelectorAll('a[href^="#"]');

  links.forEach((link) => {
    const href = link.getAttribute('href');

    if (href == '#') return;

    const target = document.querySelector(href);

    // If the target does not exist, skip it
    if (!target) return;

    link.addEventListener('click', (e) => {
      e.preventDefault();
      target.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    });
  });
}
